import { Component, Input } from '@angular/core';
import {
	IconBadgeComponent,
	TitleDescriptionComponent
} from '@array-app/frontend/common';
import { AlgoliaGroup } from '@array-app/shared/types';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	standalone: true,
	selector: 'app-global-search-group-row',
	imports: [TitleDescriptionComponent, IconBadgeComponent, TranslateModule],
	templateUrl: './group-row.component.html',
	styleUrls: ['../global-search-row.component.scss']
})
export class GlobalSearchGroupRowComponent {
	@Input()
	group!: AlgoliaGroup;
}
