<app-algolia-user-avatar class="flex-0" [user]="user"></app-algolia-user-avatar>

<app-title-description
	class="flex-1"
	[title]="user.name"
	[description]="
		('users.label.singular' | translate) +
		(user.position ? ' • ' + user.position : '')
	"
></app-title-description>

<app-algolia-user-status
	class="flex-0"
	size="small"
	[user]="user"
></app-algolia-user-status>
