<app-search-wrapper
	[canClear]="!!pagination.options.search"
	(clear)="onClear()"
>
	<input
		#matTrigger="matMenuTrigger"
		[matMenuTriggerFor]="searchMenu"
		type="text"
		#globalSearchInput
		[placeholder]="'global-search.label' | translate"
	/>
</app-search-wrapper>

<mat-menu #searchMenu class="global-search-menu" xPosition="before">
	<ng-container *ngIf="!pagination.loading; else loader">
		<ng-container *ngIf="!pagination.error; else error">
			<ng-container *ngIf="pagination.content.length; else empty">
				<div class="content-container">
					<ng-container *ngFor="let content of pagination.content">
						<app-global-search-user-row
							*ngIf="content.type === 'user'"
							[user]="content.value"
							[tabindex]="0"
							(click)="onContentSelected(content)"
						></app-global-search-user-row>

						<app-global-search-product-row
							*ngIf="content.type === 'product'"
							[product]="content.value"
							[tabindex]="0"
							(click)="onContentSelected(content)"
						></app-global-search-product-row>

						<app-global-search-tag-row
							*ngIf="content.type === 'tag'"
							[tag]="content.value"
							[tabindex]="0"
							(click)="onContentSelected(content)"
						></app-global-search-tag-row>

						<app-global-search-group-row
							*ngIf="content.type === 'group'"
							[group]="content.value"
							[tabindex]="0"
							(click)="onContentSelected(content)"
						></app-global-search-group-row>

						<app-global-search-external-link-row
							*ngIf="content.type === 'externalLink'"
							[link]="content.value"
							[tabindex]="0"
							(click)="onContentSelected(content)"
						></app-global-search-external-link-row>
					</ng-container>
				</div>
				<div class="content-footer">
					<p class="text-tertiary small margin-0 text-right">
						{{
							'tables.show-#-result'
								| translate: { count: pagination.size }
						}}
					</p>
				</div>
			</ng-container>
		</ng-container>
	</ng-container>
</mat-menu>

<ng-template #empty>
	<app-resource-list-empty-state
		size="small"
		icon="fa-solid fa-magnifying-glass"
		header="Global search"
		subheader="Start typing to see search results"
		[searching]="!!pagination.options.search"
		[canClear]="false"
	></app-resource-list-empty-state>
</ng-template>

<ng-template #error>
	<app-resource-list-empty-state
		size="small"
		icon="fa-solid fa-circle-exclamation"
		header="Error"
		subheader="There was an error, please try again later"
		[canClear]="false"
	></app-resource-list-empty-state>
</ng-template>

<ng-template #loader>
	<div class="full-width text-center padding-6">
		<app-loading-spinner></app-loading-spinner>
	</div>
</ng-template>
