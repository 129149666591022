import { Component, Input } from '@angular/core';
import { TitleDescriptionComponent } from '@array-app/frontend/common';
import { ProductCardComponent } from '@array-app/frontend/products';
import { AlgoliaProduct } from '@array-app/shared/types';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	standalone: true,
	imports: [ProductCardComponent, TitleDescriptionComponent, TranslateModule],
	selector: 'app-global-search-product-row',
	templateUrl: './product-row.component.html',
	styleUrls: ['../global-search-row.component.scss']
})
export class GlobalSearchProductRowComponent {
	@Input()
	product!: AlgoliaProduct;
}
