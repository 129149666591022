<app-icon-badge class="flex-0" icon="fa-solid fa-link"></app-icon-badge>

<app-title-description
	class="flex-1"
	[title]="link.name"
	[description]="
		('external-links.label.singular' | translate) +
		' • ' +
		((link.productCount === 1
			? 'external-links.product-singular-#'
			: 'external-links.product-plural-#'
		) | translate: { count: link.productCount })
	"
></app-title-description>

<app-text-badge *ngIf="isExpired" class="flex-0" type="danger">
	{{ 'generic.expired' | translate }}
</app-text-badge>
