import { Component, Input } from '@angular/core';
import {
	IconBadgeComponent,
	TitleDescriptionComponent
} from '@array-app/frontend/common';
import { AlgoliaTag } from '@array-app/shared/types';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	standalone: true,
	imports: [IconBadgeComponent, TitleDescriptionComponent, TranslateModule],
	selector: 'app-global-search-tag-row',
	templateUrl: './tag-row.component.html',
	styleUrls: ['../global-search-row.component.scss']
})
export class GlobalSearchTagRowComponent {
	@Input()
	tag!: AlgoliaTag;
}
