import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import {
	IconBadgeComponent,
	TextBadgeComponent,
	TitleDescriptionComponent
} from '@array-app/frontend/common';
import { AlgoliaExternalLink } from '@array-app/shared/types';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	standalone: true,
	imports: [
		IconBadgeComponent,
		TitleDescriptionComponent,
		TranslateModule,
		TextBadgeComponent,
		NgIf
	],
	selector: 'app-global-search-external-link-row',
	templateUrl: './external-link-row.component.html',
	styleUrls: ['../global-search-row.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalSearchExternalLinkRowComponent {
	@Input()
	link!: AlgoliaExternalLink;

	/**
	 * returns `true` if the link is currently expired in the system
	 */
	get isExpired() {
		if (!this.link.expiresAtSeconds) {
			return false;
		}

		return this.link.expiresAtSeconds < Timestamp.now().seconds;
	}
}
