import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { API_URL_TOKEN, AuthService } from '@array-app/frontend/authentication';
import {
	GlobalSearchOptions,
	GlobalSearchResult,
	Paginated
} from '@array-app/shared/types';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class GlobalSearchApiService {
	/**
	 * The entire collection path including the baseCollectionPath
	 */
	organizationId: string | undefined = undefined;

	/**
	 * Can be subscribed to by the global search components so any
	 * component or logic can invoke a global search to start.
	 */
	readonly requestSearch$ = new Subject<string>();

	/**
	 * The base url set by the environment
	 */
	public readonly baseUrl = this.injector.get(API_URL_TOKEN);

	/**
	 * The generated url that each api service will prefix their http requests with
	 */
	get url() {
		if (this.organizationId) {
			return `${this.baseUrl}/v1/organizations/${this.organizationId}`;
		}
		return `${this.baseUrl}/v1`;
	}

	constructor(
		private readonly authService: AuthService,
		private readonly injector: Injector,
		private readonly http: HttpClient
	) {
		const org = this.authService.organization$.value;
		this.organizationId = org?.id;
	}

	/**
	 * When called, it will invoke the new search term into the `search$`
	 * subject so any listening components can do what they need to with it.
	 * @param value the value to search by
	 */
	search(value: string) {
		this.requestSearch$.next(value);
	}

	/**
	 * Searches and returns records across the entire organization organized
	 * by the type of record.
	 *
	 * @param options configuration options to affect the search
	 * @returns an array of results
	 */
	search$(options: GlobalSearchOptions) {
		return this.http.post<Paginated<GlobalSearchResult>>(
			`${this.url}/global-search`,
			options
		);
	}
}
