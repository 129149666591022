import { Component, Input } from '@angular/core';
import { TitleDescriptionComponent } from '@array-app/frontend/common';
import {
	AlgoliaUserAvatarComponent,
	AlgoliaUserStatusComponent
} from '@array-app/frontend/users';
import { AlgoliaUser } from '@array-app/shared/types';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	standalone: true,
	imports: [
		AlgoliaUserAvatarComponent,
		TitleDescriptionComponent,
		AlgoliaUserStatusComponent,
		TranslateModule
	],
	selector: 'app-global-search-user-row',
	templateUrl: './user-row.component.html',
	styleUrls: ['../global-search-row.component.scss']
})
export class GlobalSearchUserRowComponent {
	@Input()
	user!: AlgoliaUser;
}
