<app-product-card
	class="flex-0"
	size="small"
	[product]="product"
	[noShadow]="true"
></app-product-card>

<app-title-description
	class="flex-1"
	[title]="product.name"
	[description]="
		('products.label.singular' | translate) +
		(product.description ? ' • ' + product.description : '')
	"
></app-title-description>
