<app-icon-badge class="flex-0" icon="fa-solid fa-tag"></app-icon-badge>

<app-title-description
	class="flex-1"
	[title]="tag.name"
	[description]="
		('tags.label.singular' | translate) +
		(tag.description ? ' • ' + tag.description : '')
	"
></app-title-description>
