import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	OnInit,
	ViewChild
} from '@angular/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import {
	DestroyService,
	LoadingSpinnerComponent,
	SearchWrapperComponent
} from '@array-app/frontend/common';
import { ResourceListEmptyStateComponent } from '@array-app/frontend/resource-list';
import { GlobalSearchResult, Paginated } from '@array-app/shared/types';
import { initialPagination } from '@array-app/shared/utils';
import { TranslateModule } from '@ngx-translate/core';
import {
	catchError,
	debounceTime,
	fromEvent,
	map,
	of,
	take,
	takeUntil
} from 'rxjs';
import { GlobalSearchApiService } from '../../services';
import { GlobalSearchRows } from './rows';

@Component({
	standalone: true,
	selector: 'app-global-search',
	imports: [
		...GlobalSearchRows,
		CommonModule,
		SearchWrapperComponent,
		MatMenuModule,
		TranslateModule,
		ResourceListEmptyStateComponent,
		LoadingSpinnerComponent
	],
	templateUrl: './global-search.component.html',
	styleUrls: ['global-search.component.scss'],
	providers: [DestroyService]
})
export class GlobalSearchComponent implements AfterViewInit, OnInit {
	/**
	 * Reference to the global search bar in the template
	 */
	@ViewChild('globalSearchInput')
	readonly globalSearchRef!: ElementRef<HTMLInputElement>;

	/**
	 * Reference to the global search bar in the template
	 */
	@ViewChild('matTrigger')
	readonly matTriggerRef!: MatMenuTrigger;

	pagination: Paginated<GlobalSearchResult> = initialPagination();

	constructor(
		private readonly globalSearchService: GlobalSearchApiService,
		private readonly router: Router,
		private readonly destroy$: DestroyService
	) {}

	ngOnInit() {
		this.globalSearchService.requestSearch$
			.pipe(takeUntil(this.destroy$), debounceTime(250))
			.subscribe((value) => {
				this.globalSearchRef.nativeElement.value = value;
				this.globalSearchRef.nativeElement.focus();
				if (value !== this.pagination.options.search) {
					this.initSearch(value);
				}
			});
	}

	ngAfterViewInit() {
		this.setupListeners();
	}

	/**
	 * Sets up the global search listeners from input actions taken in the html.
	 */
	setupListeners() {
		fromEvent(this.globalSearchRef.nativeElement, 'input')
			.pipe(
				takeUntil(this.destroy$),
				map(
					(ev: Event) =>
						(ev.target as HTMLInputElement)?.value as string
				),
				debounceTime(500)
			)
			.subscribe((value) => this.initSearch(value));

		fromEvent(this.globalSearchRef.nativeElement, 'focus')
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.matTriggerRef.openMenu();
			});
	}

	/**
	 * Reusable function to determine some introductory functionalities for searching.
	 *
	 * @param value the value to search by
	 */
	initSearch(value: string) {
		this.pagination.options.search = value;
		if (value) {
			this.search();
			if (!this.matTriggerRef.menuOpen) {
				this.matTriggerRef.openMenu();
			}
		} else {
			this.pagination.content = [];
		}
	}

	/**
	 * Starts the searching process based on the value provided
	 *
	 * @param search search value
	 */
	search() {
		this.pagination.loading = true;
		this.pagination.error = false;

		this.globalSearchService
			.search$(this.pagination.options)
			.pipe(
				take(1),
				catchError(() => {
					this.pagination.error = true;
					return of([]);
				})
			)
			.subscribe((results) => {
				Object.assign(this.pagination, results);
				this.pagination.loading = false;
			});
	}

	onContentSelected(content: GlobalSearchResult) {
		this.onClear(false);
		this.router
			.navigate([
				'app',
				this.determineContentTypeRoute(content),
				content.value.id
			])
			.finally(() => {
				this.globalSearchRef.nativeElement.blur();
			});
	}

	/**
	 * Determines what the route should be that the user will be directed to based
	 * on the content selected in the search results
	 *
	 * @param content the content of the global search return type to determine against
	 * @returns a string usable for the apps routing structure
	 */
	determineContentTypeRoute(content: GlobalSearchResult) {
		switch (content.type) {
			case 'externalLink':
				return 'external-links';
			default:
				return `${content.type}s`;
		}
	}

	/**
	 * Clears the search value, input, and refocuses the search bar when
	 * the user takes the clear action in the html
	 */
	onClear(focus = true) {
		this.pagination.options.search = '';
		this.pagination.content = [];
		this.pagination.error = false;
		this.globalSearchRef.nativeElement.value = '';
		if (focus) {
			this.globalSearchRef.nativeElement.focus();
		}
	}

	@HostListener('document:keydown.meta.g', ['$event'])
	shortcut(event: Event) {
		event.preventDefault();
		this.globalSearchRef.nativeElement.focus();
		this.matTriggerRef.openMenu();
	}
}
